import styled from '@emotion/styled'
import { LocalStorageUtil } from 'cuenect-web-core'
import { Form, Small, TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Heading,
  Input,
  LineContainer,
  Paragraph,
  Select,
  Textarea,
  TimezoneSwitch,
} from '../..'
import { AppointmentService, LocalData } from './../../../api'
import { eventConfig } from './../../../config'
import {
  AppointmentTopics,
  getAbbr,
  Topic,
  useModalContext,
} from './../../../utility'
import { mq } from './../../../utility/breakpoint'
interface FormFieldProps {
  left?: boolean | undefined
  right?: boolean | undefined
  thirdLeft?: boolean | undefined
  thirdRight?: boolean | undefined
  thirdCenter?: boolean | undefined
}

interface AppointmentSlotRaw {
  id: number
  start: string
  end: dstring
}

interface AppointmentSlot {
  id: number
  start: dayjs.Dayjs
  end: dayjs.Dayjs
}

export interface AppointmentFormProps {
  topics: any

  onSubmit?(data: AppointmentFormData): void
  onReset?: Function
}

export interface AppointmentFormData {
  name: string
  company: string
  country: string
  meetingLanguage: string
  industry: string
  topicLevel1: string
  topicLevel2: string
  topicLevel3: string
  topicLevel3_1: string
  expert: string
  note: string
  preferedDate: string
  alternativeDate: string
  alternativeSecondDate: string
}

export const AppointmentForm = React.forwardRef<
  HTMLFormElement,
  AppointmentFormProps
>((props, ref) => {
  const { register, handleSubmit, errors, getValues } = useForm<
    AppointmentFormData
  >()

  const [sending, setSending] = React.useState<boolean>(false)
  const { t } = useTranslation('dialog')

  const [slots, setSlots] = React.useState<AppointmentSlot[] | null>(null)

  const [slotsRaw, setSlotsRaw] = React.useState<AppointmentSlotRaw[] | null>(
    null
  )

  const userTimezone: string = TimeUtil.getUsersTimezone()
  const eventTimezone: string = eventConfig.timezone
  const [displayTimezone, setDisplayTimezone] = React.useState(eventTimezone)
  const [currentAbbr, setCurrentAbbr] = React.useState(
    getAbbr(displayTimezone, i18n.language, TimeUtil.getNow())
  )

  const [date1, setDate1] = React.useState<number>(0)
  const [date2, setDate2] = React.useState<number>(0)
  const [date3, setDate3] = React.useState<number>(0)

  const [topicLevel1, setTopicLevel1] = React.useState<string | null>('0')
  const [topicLevel2, setTopicLevel2] = React.useState<string | null>('0')
  const [topicLevel3, setTopicLevel3] = React.useState<string | null>('0')
  const [topicLevel3_1, setTopicLevel3_1] = React.useState<string | null>('0')

  const [note, setNote] = React.useState<string | null>()

  const [
    topicLevel3DirectSelection,
    setTopicLevel3DirectSelection,
  ] = React.useState<boolean>(false)

  const [level3Overview, setLevel3Overview] = React.useState<boolean>(false)

  const [topicsLevel1, setTopicsLevel1] = React.useState<Topic[]>(
    AppointmentTopics.getMainTopics(props.topics)
    /* AppointmentTopics.getMainTopics() */
  )
  const [topicsLevel2, setTopicsLevel2] = React.useState<Topic[] | null>(null)
  const [topicsLevel3, setTopicsLevel3] = React.useState<Topic[] | null>(null)

  const [timezoneChecked, setTimezoneChecked] = React.useState<boolean>(false)

  const getAppointmentSlots = async () => {
    const appointmentSlots: AppointmentSlotRaw[] = await AppointmentService.getAppointmentSlots()
    setSlotsRaw(appointmentSlots)
    setSlots(
      appointmentSlots

        .map(({ id, start, end }) => ({
          id,
          start: TimeUtil.getUtc(start),
          end: TimeUtil.getUtc(end),
          range: TimeUtil.getRangeString(
            TimeUtil.convertToTimeZone(TimeUtil.getUtc(start), displayTimezone),
            TimeUtil.convertToTimeZone(TimeUtil.getUtc(end), displayTimezone)
          ),
        }))
        .filter(({ start }) => start.isAfter(TimeUtil.getNow().add(2, 'hour')))
    )
  }

  React.useEffect(() => {
    setCurrentAbbr(getAbbr(displayTimezone, i18n.language, TimeUtil.getNow()))

    if (slotsRaw) {
      setSlots(
        slotsRaw
          .map(({ id, start, end }) => ({
            id,
            start: TimeUtil.getUtc(start),
            end: TimeUtil.getUtc(end),
            range: TimeUtil.getRangeString(
              TimeUtil.convertToTimeZone(
                TimeUtil.getUtc(start),
                displayTimezone
              ),
              TimeUtil.convertToTimeZone(TimeUtil.getUtc(end), displayTimezone)
            ),
          }))
          .filter(({ start }) =>
            start.isAfter(TimeUtil.getNow().add(1, 'hour'))
          )
      )
    }
  }, [displayTimezone])

  const rangeString = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    //return `${TimeUtil.convertToTimeZone(start, displayTimezone)}`
  }

  const dateSlot = (exclude: number[] = []) => (
    <>
      <option disabled value="0" />
      {slots
        ?.sort((a, b) => (a.start.isAfter(b.start) ? 1 : -1))
        .map(({ id, start, range }, index) => (
          <option disabled={exclude.includes(id)} value={id} key={index}>
            {`${range} ${currentAbbr}`}
          </option>
        ))}
    </>
  )

  const handleReset = () => {
    setDate1(0)
    setDate2(0)
    setDate3(0)

    setTopicLevel1('0')
    setTopicLevel2('0')
    setTopicLevel3('0')
    setTopicLevel3_1('0')
    setNote('')
  }

  React.useEffect(() => {
    setDisplayTimezone(timezoneChecked ? userTimezone : eventTimezone)
  }, [timezoneChecked])

  React.useEffect(() => {
    setTopicsLevel3(null)
    const _level2 = AppointmentTopics.getLevel2Topics(props.topics, topicLevel1)
    setTopicsLevel2(_level2)
    if (_level2 && _level2.length < 2) {
      setTopicLevel2(_level2[0].uuid)
    }

    setTopicLevel3DirectSelection(false)
  }, [topicLevel1])

  React.useEffect(() => {
    setTopicLevel2('0')
    setTopicLevel3('0')
    setTopicLevel3_1('0')
  }, [topicLevel1])

  React.useEffect(() => {
    setTopicLevel3('0')
    setTopicLevel3_1('0')
    const lvl3: Topic[] | null = AppointmentTopics.getLevel3Topics(
      props.topics,
      topicLevel2
    )

    setTopicsLevel3(lvl3)
    setTopicLevel3DirectSelection(false)

    setLevel3Overview(false)

    if (AppointmentTopics.getLevel2BusinessUnit(props.topics, topicLevel2)) {
      setLevel3Overview(true)
    }
    // SELECT IF ONLY ONE TOPIC ON LEVEL3 AND LEVEL2 HAS NO POSITION
    if (
      lvl3 &&
      topicLevel2 &&
      !AppointmentTopics.getLevel2BusinessUnit(props.topics, topicLevel2)
    ) {
      setTopicsLevel3(lvl3)

      setTopicLevel3DirectSelection(true)
    }
  }, [topicLevel2])

  React.useEffect(() => {
    getAppointmentSlots()
  }, [])

  const parseSelect = (
    data: Topic[] | null,
    hasEmpty?: boolean,
    exclude?: string | null,
    addOverview?: boolean
  ) => {
    return (
      <>
        {hasEmpty ? (
          <>
            <option value="0"></option>
            {/*   {addOverview && <option value="-1">Overview</option>} */}
          </>
        ) : (
          <></>
        )}
        {data ? (
          data.map(({ label, uuid }, index) => (
            <option disabled={exclude === uuid} value={uuid} key={index}>
              {label}
            </option>
          ))
        ) : (
          <></>
        )}
      </>
    )
  }

  const validateSlot = (value: string) => {
    return `${value}` !== '0'
  }

  const topicValue = () =>
    topicLevel1 !== '0' ||
    topicLevel2 !== '0' ||
    topicLevel3 !== '0' ||
    topicLevel3_1 !== '0'

  const validateTopicNote = () => {
    return topicLevel1 !== '0'
  }

  const validateLevel2 = () => {
    if (topicsLevel2) {
      return topicLevel2 !== '0'
    }

    return topicsLevel1 === '0'
  }

  console.log(topicLevel3)

  return (
    <div>
      <LineContainerFlex>
        <Heading type="h4">{t('form.intro.header')}</Heading>
        <Small> {t('form.intro.required')}</Small>
      </LineContainerFlex>
      <Form
        ref={ref}
        onReset={() => {
          handleReset()
        }}
        onSubmit={handleSubmit(data => {
          /*            setSending(true)
           */ props.onSubmit && props.onSubmit(data)
        })}
      >
        <input
          name="country"
          ref={register({ required: true })}
          type="hidden"
          value={parseUserData('{country}')}
        />
        <input
          name="meetingLanguage"
          ref={register({ required: true })}
          type="hidden"
          value={i18n.language}
        />
        <AppointmentFormContent>
          <FormColumn>
            <Heading type="h5">{t('form.headline.contact')}</Heading>
            {wrapInput('name', Input, {
              placeholder: t('form.fields.name'),
              value: parseUserData('{firstName} {lastName}'),
              name: `name`,
              disabled: true,
            })}
            {wrapInput('company', Input, {
              placeholder: t('form.fields.company'),
              name: `company`,
              value: parseUserData('{company}'),
              disabled: true,
            })}
            <NotUser>
              <Paragraph>
                <a href={t('form.notUser.uri')} target="_blank">
                  {parseUserData(t('form.notUser.label'))}
                </a>
              </Paragraph>
            </NotUser>
            <TimezoneSwitchContainer>
              <Heading type="h5">{t('form.headline.date')}</Heading>
              {userTimezone !== eventTimezone && (
                <TimezoneSwitch
                  userTimezone={userTimezone}
                  eventTimezone={eventTimezone}
                  onTimezoneChange={checked => {
                    setTimezoneChecked(!checked)
                  }}
                  timezoneChecked={timezoneChecked}
                />
              )}
            </TimezoneSwitchContainer>
            {slots?.length > 0 &&
              wrapInput('preferedDate', Select, {
                placeholder: `${t('form.fields.preferedDate')}*`,
                name: 'preferedDate',
                defaultValue: date1,
                onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                  setDate1(Number(e.currentTarget.value)),
                children: dateSlot([date2, date3]),
                ref: register({ required: true, validate: validateSlot }),
                error: errors[`preferedDate`],
              })}
            {slots?.length > 1 &&
              wrapInput('alternativeDate', Select, {
                placeholder: `${t('form.fields.alternativeDate')}*`,
                name: 'alternativeDate',
                defaultValue: date2,
                onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                  setDate2(Number(e.currentTarget.value)),
                children: dateSlot([date1, date3]),
                ref: register({ required: true, validate: validateSlot }),
                error: errors[`alternativeDate`],
              })}{' '}
            {slots?.length > 2 &&
              wrapInput('alternativeSecondDate', Select, {
                placeholder: `${t('form.fields.alternativeSecondDate')}*`,
                name: 'alternativeSecondDate',
                defaultValue: date3,
                onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                  setDate3(Number(e.currentTarget.value)),
                children: dateSlot([date1, date2]),
                ref: register({ required: true, validate: validateSlot }),
                error: errors[`alternativeSecondDate`],
              })}
          </FormColumn>
          <FormColumn right>
            <Heading type="h5">{t('form.headline.theme')}</Heading>
            {/* {!validateTopicNote() && Object.keys(errors).length > 0 && (
              <TopicError> {t('form.errors.requiredOne')}</TopicError>
            )} */}
            {wrapInput('topicLevel1', Select, {
              placeholder: `${t('form.fields.topicLevel1')}*`,
              name: 'topicLevel1',
              children: parseSelect(topicsLevel1, true),
              onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                setTopicLevel2('0')
                setTopicLevel1(e.currentTarget.value)
              },
              ref: register({ required: true }),
              error:
                !validateTopicNote() && Object.keys(errors).length > 0
                  ? 'Invalid topicLevel2'
                  : '',
            })}
            {wrapInput('topicLevel2', Select, {
              placeholder: `${t('form.fields.topicLevel2')}*`,
              name: 'topicLevel2',
              onChange: (e: React.FormEvent<HTMLSelectElement>) => {
                setTopicLevel2(e.currentTarget.value)
                setTopicLevel3('0')
                setTopicLevel3_1('0')
              },

              disabled: !topicsLevel2,
              children: parseSelect(
                topicsLevel2,
                topicsLevel2 && topicsLevel2.length > 1
              ),
              value: topicLevel2,
              /*               value: topicLevel2,
               */ ref: register({
                required: true,
                validate: () => !(topicLevel2 === '0'),
              }),
              error:
                !validateLevel2() && Object.keys(errors).length > 0
                  ? 'Invalid topicLevel2'
                  : '',
            })}

            {wrapInput('topicLevel3', Select, {
              placeholder: `${t('form.fields.topicLevel3')}${
                topicsLevel3?.length ? '*' : ''
              }`,
              name: 'topicLevel3',
              onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                setTopicLevel3(e.currentTarget.value),
              children: parseSelect(
                topicsLevel3,
                !topicLevel3DirectSelection ||
                  (topicsLevel3 && topicsLevel3.length > 1),
                topicLevel3_1,
                level3Overview
              ),
              disabled: !topicsLevel3,
              ref: register({
                required: true,
                validate: () =>
                  !(
                    topicLevel3 === '0' &&
                    topicLevel3_1 === '0' &&
                    topicsLevel3 &&
                    topicsLevel3.length > 1
                  ),
              }),
              value: topicLevel3,
              /*               value: topicLevel3,
               */ error:
                topicLevel3 === '0' &&
                topicLevel3_1 === '0' &&
                topicsLevel3 &&
                topicsLevel3.length > 1 &&
                Object.keys(errors).length > 0
                  ? 'Invalid topicLevel3'
                  : '',
            })}

            {wrapInput('topicLevel3_1', Select, {
              placeholder: t('form.fields.topicLevel3'),
              name: 'topicLevel3_1',
              onChange: (e: React.FormEvent<HTMLSelectElement>) =>
                setTopicLevel3_1(e.currentTarget.value),
              disabled:
                !topicsLevel3 ||
                (topicLevel3DirectSelection && topicsLevel3.length < 2),
              children: parseSelect(
                topicsLevel3,
                true,
                topicLevel3,
                level3Overview
              ),
              ref: register({
                required: true,
                validate:
                  !topicsLevel3 ||
                  (topicLevel3DirectSelection && topicsLevel3.length < 2),
              }),
              value: topicLevel3_1,
              error:
                !topicValue() &&
                !(
                  !topicsLevel3 ||
                  (topicLevel3DirectSelection && topicsLevel3.length < 2)
                ) &&
                Object.keys(errors).length > 0
                  ? 'Invalid topicLevel3_1'
                  : '',
            })}
            {wrapInput('note', Textarea, {
              rows: 4,
              onChange: (e: React.FormEvent<HTMLTextAreaElement>) =>
                setNote(e.currentTarget.value),
              placeholder: `${t('form.fields.note')}`,
              defaultValue: note,
              ref: register(),
              name: `note`,
              value: note,
              error:
                errors[`note`] && Object.keys(errors).length > 0 ? ' ' : '',
            })}
          </FormColumn>

          <ButtonHolder>
            <Button type="submit" disabled={sending}>
              {t('form.fields.submit')}
            </Button>
          </ButtonHolder>
        </AppointmentFormContent>
      </Form>
    </div>
  )
})

interface AppointmentFormSentProps {
  success?: boolean
  onClose?(success: boolean): void
}
export const AppointmentFormSent: react.FC<AppointmentFormSentProps> = ({
  success,
  onClose,
}) => {
  const { dispatch: modalDispatch } = useModalContext()
  const { t } = useTranslation('dialog')

  return (
    <AppointmentFormSentContainer>
      <Heading type="h3">
        {t(`form.sent.${success ? 'success' : 'error'}.headline`)}
      </Heading>
      <Paragraph>
        {t(`form.sent.${success ? 'success' : 'error'}.description`)}
      </Paragraph>
      <Button
        onClick={() => {
          onClose && onClose(success)
          modalDispatch({
            type: 'HIDE_MODAL',
          })
        }}
      >
        {t('form.sent.close')}
      </Button>
    </AppointmentFormSentContainer>
  )
}

const wrapInput = (
  name: keyof AppointmentFormData,
  element: React.FC,
  // tslint:disable-next-line: no-any
  props: any
) => {
  return <FormElement>{React.createElement(element, { ...props })}</FormElement>
}

const parseUserData = (input: string) => {
  const userData = LocalStorageUtil.getObject<LocalData | null>('user')?.person

  if (!userData) {
    return input
  }

  const { fields, salutation, firstName, lastName } = userData
  const { company, country } = fields || { company: '', country: '' }

  return input
    .replace('{salutation}', salutation[i18n.language || 'en'])
    .replace('{company}', company)
    .replace('{firstName}', firstName)
    .replace('{lastName}', lastName)
    .replace('{country}', country)
}

const LineContainerFlex = styled(LineContainer)({
  marginBottom: '20px',
  '& > div:not(:first-of-type)': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
})

const TimezoneSwitchContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const AppointmentFormSentContainer = styled.div(
  ({ theme: { grid } }) => `
  max-width:90vw;
  ${[mq[3]]}{
    min-width:400px;
  }
  text-align:center;
  button{
    display: inline-block;
  }
`
)

const AppointmentFormContent = styled.div(
  ({ theme: { grid } }) => `
  display:grid;
  grid-template-columns: repeat(${grid.columns - 4},1fr);
  margin-bottom:60px;
`
)

const FormColumn = styled.div<FormFieldProps>(
  ({ theme: { grid }, right, thirdLeft, thirdCenter, thirdRight }) => {
    let g = '1/16'
    if (right) {
      g = '18/34'
    }
    if (thirdLeft) {
      g = '1/11'
    }
    if (thirdCenter) {
      g = '12/22'
    }
    if (thirdRight) {
      g = '23/34'
    }

    return `

      position:relative;
      grid-column:1/34;
      ${mq[3]} {grid-column: ${g} };
      h5{
        margin-bottom:25px;
      }
    `
  }
)
const NotUser = styled.div(
  () => `
  display:flex;
  justify-content:flex-end;
  margin-top:-20px;
  p{
    font-size:12px;
  }
  `
)

const ButtonHolder = styled.div<FormFieldProps>(
  ({ theme: { grid }, right }) => `
  grid-column: 1/34;
  text-align:right;
  display: flex;
  justify-content: flex-end;
  `
)

const FormElement = styled.div<FormFieldProps>(
  ({ theme: { grid }, right }) => `
  margin-bottom:20px;
 input{
    width:100%;
  }
 `
)

const TopicError = styled.div(
  ({ theme: { colors } }) => `
  color:${colors.error};
  position:absolute;
  margin-top:-20px;
  font-size:14px;
`
)
