import styled from '@emotion/styled'
import { Controls, VideoProps, withVideoControls } from 'cuenect-web-core'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentContainer } from '../components'
import {
  BigCountdown,
  Button,
  ContentColumn,
  Heading,
  LineContainer,
  Paragraph,
} from '../components'
import { LoadingIndicator } from '../components/atoms/loadingIndicator'
import {
  AppointmentForm,
  AppointmentFormSent,
} from '../components/organisms/appointmentForm'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { AppointmentService, TopicsService } from './../api'
import { isPostEventFirst } from './../config'
import { mq } from './../utility/breakpoint'
import { useModalContext } from './../utility/modalContext'

const DialogPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)
  const [topics, setTopics] = React.useState()
  const [scroller, setScroller] = React.useState(null)
  const isPost = isPostEventFirst()

  const appointmentFormRef = React.useRef()

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en/${document.location.search}`)
    }

    return <></>
  }

  const { t } = useTranslation('dialog')
  const { dispatch: modalDispatch } = useModalContext()
  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  // Scroll to form when has hash
  const [appointmentFormPos, setAppointmentFormPos] = React.useState()
  const handleRect = React.useCallback(node => {
    setAppointmentFormPos(node?.getBoundingClientRect().y)
  }, [])

  React.useEffect(() => {
    if (loaded && scroller && appointmentFormPos) {
      if (document.location.hash === '#appointment') {
        scroller.scrollTop = appointmentFormPos
      }
    }
  }, [appointmentFormPos, scroller, loaded])

  const loadTopics = async () => {
    const _topics = await TopicsService.getTopics()
    setTopics(_topics)
  }
  React.useEffect(() => {
    loadTopics()
  }, [])

  /*  React.useEffect(() => {
    const d =
      '{"country":"Germany","meetingLanguage":"en","topicLevel1":"a9e4a524-d047-447b-861f-abdd2bffd09f","topicLevel2":"2fe2c911-faf8-4b95-83f5-52bbf700138a","note":"","preferedDate":"5863","alternativeDate":"5864","alternativeSecondDate":"5865"}'

    AppointmentService.sendForm(JSON.parse(d))
  }, []) */

  const sendForm = data => {
    modalDispatch({
      type: 'OPEN_WITH_COMPONENT',
      state: {
        component: LoadingIndicator,
      },
    })

    AppointmentService.sendForm(data, topics).then(({ status }) => {
      modalDispatch({
        type: 'OPEN_WITH_COMPONENT',
        state: {
          component: AppointmentFormSent,
          params: {
            success: status === 204,
            onClose: success => {
              if (success) {
                appointmentFormRef.current.reset()
              }
            },
          },
        },
      })
    })
  }

  return (
    <PageFrame
      {...params}
      pageName="dialog"
      onScrollerInit={elem => {
        setScroller(elem)
      }}
    >
      {loaded && topics && (
        <>
          <ContentContainer>
            <StartTeaser>
              <ContentColumn
                left={<img src={t(`start.visual`)} />}
                right={
                  <div>
                    <Heading type="h4">
                      {t(`${isPost ? 'startPostEvent' : 'start'}.header`)}
                    </Heading>
                    <Paragraph>
                      {parse(t(`${isPost ? 'startPostEvent' : 'start'}.copy`))}
                    </Paragraph>
                    {isPost && (
                      <Button
                        onClick={() =>
                          window.open(t(`startPostEvent.cta.link`))
                        }
                      >
                        {t(`startPostEvent.cta.label`)}
                      </Button>
                    )}
                  </div>
                }
              />
            </StartTeaser>
            <Spacer />
          </ContentContainer>
          <div ref={handleRect} />
          {!isPost && (
            <ContentContainer>
              <AppointmentForm
                topics={topics}
                ref={appointmentFormRef}
                onSubmit={data => sendForm(data)}
              />
            </ContentContainer>
          )}
        </>
      )}
    </PageFrame>
  )
}

export default DialogPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
}

const StartTeaser = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    ${[mq[3]]}{
      display:flex;
      align-items:center;
    }`
  };
  ${
    !fullheight &&
    `margin-top:60px;
    ${[mq[3]]}{
      margin-top:0;
    }`
  };


`
)

const Spacer = styled.div`
  margin: 95px 0;
  ${mq[3]} {
    margin: 125px 0;
  }
`
